import React from 'react';
import Container from '../components/Container'; // Importando o Container
import '../styles/About.css'; // Importando o CSS atualizado

function About() {
  return (
    <Container> {/* Envolvendo todo o conteúdo com o Container */}
      <div >
        its coming
      </div>
    </Container>
  );
}

export default About;
